import React from 'react'

import googleImg from '../img/googleStoreImage.png'
import appleImg from '../img/iOSStoreImage.png'

const StoreLinks = ({androidStoreLink, iOSStoreLink, webLink, title}) => <div className="store-links">
  { iOSStoreLink &&
    <a href={iOSStoreLink} target="_blank"><img src={appleImg} alt={`Download ${title} from Apple App Store`} style={{width: 135, height: 40}}/></a>}
  
  { androidStoreLink &&
    <a href={androidStoreLink} target="_blank"><img src={googleImg} alt={`Download ${title} from Google Play`} style={{ width: 135, height: 40}}/></a>}
    
  { webLink &&
    <a href={webLink} alt={`${title}`} target="_blank"><div className="webLink">{title.length < 16 ? title : "Web"}</div></a>}
</div>

export default StoreLinks;