import React from "react";
import Helmet from "react-helmet";
import Header from "./header";
import Footer from "./footer";

import "./index.styl";

export default function Layout({ children }: { children: any }) {
  return (
    <div>
      <Helmet
        title="Isometrically (from James Porter)"
        meta={[
          {
            name: "description",
            content: "Create Isometric art on iPad and iPhone",
          },
        ]}
      />
      <Header />

      <div className="content">
        <div className="main-content">{children}</div>
      </div>

      <Footer />
    </div>
  );
}
