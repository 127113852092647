import React from 'react'
import Link from 'gatsby-link'
import StoreLinks from './storeLinks'

const Header = () =>
  <div className="header">
    <div className="header-container">

      <Link to="/" className="header-home">Isometrically</Link>
      <p className="description">Create 3D-ish Art on iPhone and iPad <a href="https://complexview.com">(from James Porter)</a>.</p>

      <div className="header-links">
        <Link to="/" exact={true} activeClassName="active">Introduction</Link>
        <Link to="/guide" activeClassName="active">Guide</Link>
        <Link to="/lessons" activeClassName="active">Lessons</Link>
      </div>

      <StoreLinks iOSStoreLink="https://itunes.apple.com/us/app/isometrically-3d-ish-graphics/id1365069631" />
    </div>
  </div>

export default Header;
